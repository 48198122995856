<template>
	<div class="pui-form">
		<mtipocanalcmnc-modals :modelName="modelName"></mtipocanalcmnc-modals>
		<!--<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<mtipocanalcmnc-form-header
				:modelPk="modelPk"
			></mtipocanalcmnc-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set :title="getTitleDatos">
						<v-layout wrap>
							<!-- IDCANAL -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idcanal-mtipocanalcmnc`"
									v-model="model.idcanal"
									:label="$t('mtipocanalcmnc.idcanal')"
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<!-- CODIGOCANAL -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`codigocanal-mtipocanalcmnc`"
									v-model="model.codigocanal"
									:label="$t('mtipocanalcmnc.codigocanal')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="5"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NAME -->
							<v-flex xs12 md6>
								<pui-text-field
									:id="`name-mtipocanalcmnc`"
									v-model="model.name"
									:label="$t('mtipocanalcmnc.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- FECBAJA -->
							<v-flex xs12 md3>
								<pui-date-field
									:id="`fecbaja-mtipocanalcmnc`"
									v-model="model.fecbaja"
									:label="$t('mtipocanalcmnc.fecbaja')"
									:disabled="formDisabled"
									toplabel
									time
								></pui-date-field>
							</v-flex>
						</v-layout>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mtipocanalcmncActions from './MtipocanalcmncActions';
import mtipocanalcmncModals from './MtipocanalcmncModals.vue';

export default {
	name: 'mtipocanalcmnc-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mtipocanalcmnc-modals': mtipocanalcmncModals
	},
	data() {
		return {
			modelName: 'mtipocanalcmnc',
			actions: mtipocanalcmncActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mtipocanalcmnc');
		}
	},
	created() {}
};
</script>
